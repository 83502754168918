import { render, staticRenderFns } from "./research-report-readership-table.vue?vue&type=template&id=7bc3622c&scoped=true"
import script from "./research-report-readership-table.vue?vue&type=script&lang=js"
export * from "./research-report-readership-table.vue?vue&type=script&lang=js"
import style0 from "./research-report-readership-table.vue?vue&type=style&index=0&id=7bc3622c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc3622c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src2285020857/src/web/components/table-filters.vue').default,LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default,TableActions: require('/codebuild/output/src2285020857/src/web/components/table-actions.vue').default})
