import { render, staticRenderFns } from "./transaction-data-form.vue?vue&type=template&id=9aefd7de&scoped=true"
import script from "./transaction-data-form.vue?vue&type=script&lang=js"
export * from "./transaction-data-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9aefd7de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Suggestion: require('/codebuild/output/src2285020857/src/web/components/suggestion.vue').default})
