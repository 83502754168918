
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import modals from '../../mixins/modals';

import TableFilters from '../table-filters';
import QuintableSearchInput from '../helpers/quintable-search-input.vue';
import TombstoneModal from './tombstone-modal.vue';
import EditTransactionDataModal from './edit-transaction-data-modal';
import DownloadTransactionsModal from './download-transactions-modal';
import InviteUserModal from './invite-user-modal';
import TransactionMoneyRaisedCell from './transactions-table-cells/transaction-money-raised-cell.vue';
import TransactionDataSourceCell from './transactions-table-cells/transaction-data-source.cell.vue';
import DuplicateTransactionsTable from './duplicate-transactions-table.vue';
import TableActions from '~/components/table-actions';
import optionButtonTooltip from '~/mixins/option-button-tooltip';

export default {
    name: 'TransactionsTable',
    components: {
        DuplicateTransactionsTable,
        TransactionDataSourceCell,
        TransactionMoneyRaisedCell,
        QuintableSearchInput,
        InviteUserModal,
        TombstoneModal,
        DownloadTransactionsModal, EditTransactionDataModal, TableFilters, flatPickr, TableActions
    },
    mixins: [optionButtonTooltip, modals],
    props: {
        showFilters: {
            type: Boolean,
            required: false,
            default: true
        },
        enableSelect: {
            type: Boolean,
            default: false
        },
        transactionCompany: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        transactionStatus: {
            type: String,
            required: false,
            default: null,
            validator: function validator(value) {
                return ['ACTIVE', 'CLOSED', 'MISSED'].includes(value);
            }
        },
        showActions: {
            type: Boolean,
            default: false
        },
        showDownload: {
            type: Boolean,
            default: true
        },
        ids: {
            type: Array,
            default: null,
            required: false
        },
        pageSize: {
            type: Number,
            default: 50
        },
        fromCompany: {
            type: Boolean,
            default: false
        },
        companiesHash: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isLoading: false,
            transactionData: null,
            suggestionsForTransactionData: null,
            modal: null,
            transactionsUpdated: moment().unix(),
            dateConfig: {
                altInput: true,
                altFormat: 'm/d/Y',
                dateFormat: 'Y-m-d'
            },
            filterOptions: {
                companies: [],
                investmentTypes: [],
                transactionTypes: [],
                statuses: [],
                hcwRoles: [],
                stakeholders: [],
                stakeholderRoles: [],
                dataSources: []
            },
            dealChecklistFilterOptions: [
                {
                    label: 'With Deal Checklist',
                    value: 1
                }, {
                    label: 'With Completed Deal Checklist',
                    value: 2
                }, {
                    label: 'With Uncompleted Deal Checklist',
                    value: 3
                }, {
                    label: 'Without Deal Checklist',
                    value: 4
                }
            ],
            selectedCompanies: [],
            selectedCompanyGroup: null,
            selectedInvestmentTypes: [],
            selectedTransactionTypes: [],
            selectedStatuses: [],
            selectedHcwRoles: [],
            selectedStakeholders: [],
            selectedStakeholderRoles: [],
            selectedDataSources: [],
            minimumMoneyRaisedInMillions: '',
            maximumMoneyRaisedInMillions: '',
            minimumDate: null,
            maximumDate: null,
            onlyFavoriteCompanies: false,
            onlyOwnCompanies: false,
            leadBankerCompanies: false,
            secondaryBankerCompanies: false,
            onlyStaffedCompanies: false,
            onlyNotStaffedCompanies: false,
            onlyCoveredCompanies: false,
            searchQuery: '',
            downloadModal: null,
            dealChecklistFilterOption: null,
            invitationTransactionId: null,
            invitationModal: null,
            tombstoneTransactionId: null,
            tombstoneModal: null,
            mostUsedCompanyGroups: [],
            selectedTab: 'CLOSED',
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            ajaxRows: null,
            ajaxAll: 0,
            hasPageChanged: false
        };
    },
    computed: {
        config() {
            const columns = [{
                headline: 'Title',
                sort: true
            }, {
                headline: 'Company',
                sort: true
            }, {
                headline: 'HCW Role',
                breakpoint: 'lg',
                sort: true
            }, {
                headline: 'Transaction Type'
            }, {
                headline: 'Status',
                sort: true
            }, {
                headline: 'Announced On',
                breakpoint: 'all',
                sort: true
            }, {
                headline: 'Pricing Date',
                breakpoint: 'all',
                sort: true
            }, {
                headline: 'Initiation Date',
                breakpoint: 'all',
                sort: true
            }, {
                headline: 'Filing Date',
                breakpoint: 'all',
                sort: true
            }, {
                headline: 'Date',
                sort: true
            }, {
                headline: 'Investment Type',
                breakpoint: 'lg',
                sort: true
            }, {
                headline: 'Money Raised',
                sort: true
            }, {
                headline: 'Actions',
                hideHeadlineBreakpoint: 'all',
                align: 'end',
                hidden: !this.showActions
            },
                {
                    headline: 'Summary',
                    breakpoint: 'all'
                }, {
                    headline: 'Investment Stage',
                    breakpoint: 'all'
                }, {
                    headline: 'Transaction Sub Type',
                    breakpoint: 'all'
                }, {
                    headline: 'HCW Gross Spread Percentage',
                    breakpoint: 'all'
                }, {
                    headline: 'HCW Econs Percentage',
                    breakpoint: 'all'
                }, {
                    headline: 'HCW Fee',
                    breakpoint: 'all'
                }, {
                    headline: 'Is Equity?',
                    breakpoint: 'all'
                }, {
                    headline: 'Analyst Coverage Count',
                    breakpoint: 'all'
                }, {
                    headline: 'Syndicate Size',
                    breakpoint: 'all'
                }, {
                    headline: 'Lead Investors',
                    breakpoint: 'all'
                }, {
                    headline: 'Other Investors',
                    breakpoint: 'all'
                }, {
                    headline: 'Other Stakeholders',
                    breakpoint: 'all'
                }, {
                    headline: 'Data Source',
                    breakpoint: 'all'
                }, {
                    headline: 'Duplicates',
                    breakpoint: 'all'
                }
            ];
            return {
                columns,
                search: this.showFilters,
                select: this.enableSelect && this.isUserWithRoleBanker, // Only for bankers, because currently marked rows can only be used to flag transactions as manual duplicates
                selectPosition: 'pre',
                selectAll: this.enableSelect && this.isUserWithRoleBanker,
                prettySelect: this.enableSelect && this.isUserWithRoleBanker,
                pageSortSelect: this.enableSelect && this.isUserWithRoleBanker,
                pagination: this.pageSize,
                ajaxUrl: '/api/transactions/list'
            };
        },
        sortOrder: {
            get() {
                // FIXME This is a temp bugfix. Sort order should not be hardcoded like this. Better use fallback in the backend.
                const defaultSortIndex = 9;
                return [{
                    index: defaultSortIndex,
                    asc: false,
                    headline: this.config.columns[defaultSortIndex].headline
                }];
            },
            set() {
                // Do nothing.
            }
        },
        includeTransactionCompany() {
            return !this.transactionCompany;
        },
        includeTransactionStatus() {
            return !this.transactionStatus;
        },
        currentMinimumDate: {
            get() {
                return this.minimumDate;
            },
            set(val) {
                if (this.maximumDate == null) {
                    // No maximum date to compare, just update minimum date.
                    this.minimumDate = val;
                } else {
                    // Compare new minimum and current maximum date.
                    const parsedMaximumDate = moment(this.maximumDate, 'YYYY-MM-DD');
                    const parsedMinimumDate = moment(val, 'YYYY-MM-DD');
                    if (parsedMaximumDate >= parsedMinimumDate) {
                        // Maximum date later than minimum update, just update minimum date.
                        this.minimumDate = val;
                    } else {
                        // Maximum date earlier than minimum date, swap them and update.
                        this.minimumDate = parsedMaximumDate.format('YYYY-MM-DD');
                        this.maximumDate = val;
                    }
                }
            }
        },
        currentMaximumDate: {
            get() {
                return this.maximumDate;
            },
            set(val) {
                if (this.minimumDate == null) {
                    // No minimum date to compare, just update maximum date.
                    this.maximumDate = val;
                } else {
                    // Compare new maximum and current minimum date.
                    const parsedMaximumDate = moment(val, 'YYYY-MM-DD');
                    const parsedMinimumDate = moment(this.minimumDate, 'YYYY-MM-DD');
                    if (parsedMaximumDate >= parsedMinimumDate) {
                        // Maximum date later than minimum update, just update maximum date.
                        this.maximumDate = val;
                    } else {
                        // Maximum date earlier than minimum date, swap them and update.
                        this.maximumDate = parsedMinimumDate.format('YYYY-MM-DD');
                        this.minimumDate = val;
                    }
                }
            }
        },
        companyIdsToFilter() {
            if (this.transactionCompany) {
                return [this.transactionCompany.id];
            } else {
                return this.selectedCompanies.map(x => x.id);
            }
        },
        stakeholderIdsToFilter() {
            return this.selectedStakeholders.map(x => x.id);
        },
        transactionStatusesToFilter() {
            if (this.transactionStatus) {
                return this.transactionStatus;
            } else {
                return this.selectedStatuses;
            }
        },
        userIdFinal() {
            return this.onlyOwnCompanies && this.userInfo ? this.userInfo.id : null;
        },
        minimumMoneyRaised() {
            if (!this.minimumMoneyRaisedInMillions) {
                return null;
            }
            const parsedMinimumMoneyRaised = parseFloat(this.minimumMoneyRaisedInMillions);
            if (!parsedMinimumMoneyRaised) {
                return null;
            }
            return parsedMinimumMoneyRaised * 1000000;
        },
        maximumMoneyRaised() {
            if (!this.maximumMoneyRaisedInMillions) {
                return null;
            }
            const parsedMaximumMoneyRaised = parseFloat(this.maximumMoneyRaisedInMillions);
            if (!parsedMaximumMoneyRaised) {
                return null;
            }
            return parsedMaximumMoneyRaised * 1000000;
        },
        filters() {
            return {
                companies: this.companyIdsToFilter,
                investmentTypes: this.selectedInvestmentTypes,
                transactionTypes: this.selectedTransactionTypes,
                statuses: this.fromCompany ? [this.selectedTab] : this.transactionStatusesToFilter,
                hcwRoles: this.selectedHcwRoles,
                stakeholders: this.stakeholderIdsToFilter,
                stakeholderRoles: this.selectedStakeholderRoles,
                dataSources: this.selectedDataSources,
                minimumMoneyRaised: this.minimumMoneyRaised,
                maximumMoneyRaised: this.maximumMoneyRaised,
                minimumDate: this.minimumDate,
                maximumDate: this.maximumDate,
                onlyFavoriteCompanies: this.onlyFavoriteCompanies,
                leadBankerCompanies: this.leadBankerCompanies,
                secondaryBankerCompanies: this.secondaryBankerCompanies,
                onlyStaffedCompanies: this.onlyStaffedCompanies,
                onlyNotStaffedCompanies: this.onlyNotStaffedCompanies,
                onlyCoveredCompanies: this.onlyCoveredCompanies,
                userId: this.userIdFinal,
                dealChecklistFilterOption: this.dealChecklistFilterOption,
                ids: this.ids,
                companyGroup: this.selectedCompanyGroup?.id,
                companiesHash: this.companiesHash
            };
        },
        downloadQuery() {
            return {
                search: this.searchQuery,
                filters: this.filters,
                sort: this.sortOrder
            };
        },
        showCompanyFilters() {
            return !this.companiesHash && !this.transactionCompany;
        }
    },
    watch: {
        filters: {
            handler() {
                this.hasPageChanged = true;
            },
            deep: true
        },
        selectedRows: {
            handler(rows) {
                if (!this.hasPageChanged && this.ajaxRows) {
                    for (let i = 0; i < rows.length; i++) {
                        if (!this.preSelectedRowIds.includes(rows[i].transaction_id)) {
                            this.preSelectedRowIds.push(rows[i].transaction_id);
                        }
                    }

                    for (let j = 0; j < this.ajaxRows.length; j++) {
                        const id = this.ajaxRows[j].transaction_id;

                        const index = this.preSelectedRowIds.indexOf(id);

                        if (!rows.map(r => r.transaction_id).includes(id) && index !== -1) {
                            this.preSelectedRowIds.splice(index, 1);
                        }
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.loadTransactionFilterOptions();
        this.loadMostUsedCompanyGroups();
        if (this.$route.query.transactionId) {
            const transactionId = parseInt(this.$route.query.transactionId.toString());
            if (transactionId) {
                this.openTombstoneModal(transactionId);
            }
        }
    },
    methods: {
        async openTombstoneModal(transactionId) {
            this.isLoading = true;
            this.tombstoneTransactionId = transactionId;
            await this.$nextTick();
            this.tombstoneModal = this.openModal(this.$refs.tombstoneModal);
            this.isLoading = false;
        },
        reportTypeUnhandled(type) {
            console.error('Type ' + type + ' is not handled!');
            return false;
        },
        loadTransactionFilterOptions() {
            if (this.showFilters) {
                this.$axios.get('/api/transactions/filter_options?ts=1688978061')
                    .then((response) => {
                        this.filterOptions = response.data;
                    });
            }
        },
        resetFilters() {
            this.transactionsUpdated = moment().unix();

            this.selectedCompanies = [];
            this.selectedInvestmentTypes = [];
            this.selectedTransactionTypes = [];
            this.selectedStatuses = [];
            this.selectedHcwRoles = [];
            this.selectedStakeholders = [];
            this.selectedStakeholderRoles = [];
            this.selectedDataSources = [];

            this.minimumMoneyRaisedInMillions = '';
            this.maximumMoneyRaisedInMillions = '';

            this.minimumDate = null;
            this.maximumDate = null;

            this.onlyFavoriteCompanies = false;
            this.onlyOwnCompanies = false;
            this.leadBankerCompanies = false;
            this.secondaryBankerCompanies = false;
            this.onlyStaffedCompanies = false;
            this.onlyNotStaffedCompanies = false;
            this.onlyCoveredCompanies = false;
            this.dealChecklistFilterOption = null;

            // Also reset search query for download
            this.searchQuery = '';
        },
        updateSearchQuery(searchQuery) {
            this.pageChanged = true;
            this.searchQuery = searchQuery;
        },
        updateSortOrder(order) {
            this.sortOrder = order;
        },
        addTransactionData(transactionId) {
            this.isLoading = true;
            Promise.all([
                this.$axios.get(`/api/transaction_datas/${transactionId}/empty`).then(response => {
                    this.transactionData = response.data;
                }),
                this.$axios.get(`/api/transaction_datas/${transactionId}/suggestions`).then(response => {
                    this.suggestionsForTransactionData = response.data;
                })
            ]).then(() => {
                this.$nextTick(() => {
                    this.modal = this.openModal(this.$refs.editTransactionDataModal);
                    this.isLoading = false;
                });
            });
        },
        editTransactionData(transactionId, transactionDataId) {
            this.isLoading = true;
            Promise.all([
                this.$axios.get(`/api/transaction_datas/${transactionDataId}`).then(response => {
                    this.transactionData = response.data;
                }),
                this.$axios.get(`/api/transaction_datas/${transactionId}/suggestions`).then(response => {
                    this.suggestionsForTransactionData = response.data;
                })
            ]).then(() => {
                this.$nextTick(() => {
                    this.modal = this.openModal(this.$refs.editTransactionDataModal);
                    this.isLoading = false;
                });
            });
        },
        transactionDataUpdated() {
            this.transactionsUpdated = moment().unix();
            this.closeModal(this.modal);
        },
        openDownloadTransactionsModal() {
            this.$nextTick(() => {
                this.downloadModal = this.openModal(this.$refs.downloadTransactionsModal);
            });
        },
        closeDownloadTransactionsModal() {
            this.closeModal(this.downloadModal);
            this.downloadModal = null;
        },
        inviteToAddDealData(transactionId) {
            this.isLoading = true;
            this.invitationTransactionId = transactionId;
            this.invitationModal = this.openModal(this.$refs.inviteUserModal);
            this.isLoading = false;
        },
        prepareInvitationMail(user) {
            this.$axios.post('/api/invite_transaction_data', {
                transaction: this.invitationTransactionId,
                user: user.id
            })
                .then((response) => {
                    this.closeModal(this.invitationModal);
                    this.invitationModal = null;
                    this.invitationTransactionId = null;
                    const draftId = response.data.draftId;
                    this.$router.push(`/emails/${draftId}`);
                });
        },
        loadMostUsedCompanyGroups() {
            return this.$axios.get('/api/company_groups/most_used').then(response => {
                this.mostUsedCompanyGroups = response.data['hydra:member'];
            });
        },
        onRowsUpdated(data) {
            this.hasPageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowIds.map(id => {
                    return {
                        key: 'transaction_id',
                        value: id
                    };
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
        },
        onPageChanged() {
            this.hasPageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        markTransactionsAsDuplicates() {
            this.isLoading = true;
            this.$axios.post(`/api/transactions/create_manual_duplicates`, {
                transactionIds: this.preSelectedRowIds
            }).then(response => {
                const toastId = this.generateUUID();

                if (response.data.success) {
                    this.updateTransactions();

                    this.addToast({
                        type: 'success',
                        title: 'Deals Updated',
                        message: `The deals have been manually marked as duplicates. The main transaction is "${response.data.mainTransactionTitle}".`,
                        id: toastId
                    });
                } else {
                    this.addToast({
                        type: 'danger',
                        title: 'Error',
                        message: response.data.message,
                        id: toastId
                    });
                }

                this.$nextTick(() => {
                    this.isLoading = false;
                    this.toggleToast(toastId);
                });
            });
        },
        updateTransactions() {
            this.transactionsUpdated = moment().unix();
        }
    }
};
